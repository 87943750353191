import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import Loadable from "react-loadable";

import "./App.scss";

import { Rehydrated } from "aws-appsync-react";
import { ApolloProvider } from "react-apollo";

import PrivateRoute from "./PrivateRoute";
import { AlertContext, SearchContext, BrandContext } from "./AppContext";
import { getEnv } from "./utils/getEnv";
import { today } from "./utils/helpers";
import {SEARCH_BY_DATE_INDEX, SEARCH_BY_STATUS_FILTER, BRAND_DATA, DEFAULT_BRAND, BRAND_IDS} from "./utils/constants";

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

// Containers
const DefaultLayout = Loadable({
  loader: () => import("./containers/DefaultLayout"),
  loading
});

// Pages
const Login = Loadable({
  loader: () => import("./views/Pages/Login"),
  loading
});

const Page404 = Loadable({
  loader: () => import("./views/Pages/Page404"),
  loading
});

const Page500 = Loadable({
  loader: () => import("./views/Pages/Page500"),
  loading
});

const initSearchState = (searchSpec, searchValue) => ({
  searchText: "",
  searchSpec: {
    ...searchSpec,
    default: true
  },
  searchValue,
});

class App extends Component {
  constructor(props) {
    super(props);

    let { history, match: { params: { brandId }}} = props;
    if (!brandId || !Object.values(BRAND_IDS).includes(brandId)) {
      // If the brand wasn't set or the path didn't include a brand then go to the brand page
      brandId = DEFAULT_BRAND;
      history.replace(`/${brandId}`);
    }

    this.state = {
      alert: null,
      tabUsers: initSearchState(SEARCH_BY_DATE_INDEX, today()),
      tabScans: initSearchState(SEARCH_BY_DATE_INDEX, today()),
      tabOrders: initSearchState(SEARCH_BY_STATUS_FILTER),
      selectedBrand: { id: brandId, ...BRAND_DATA[brandId] },
    };

    this.setAlert = this.setAlert.bind(this);
    this.clearAlert = this.clearAlert.bind(this);
    this.setSearchState = this.setSearchState.bind(this);
    this.setLocalSearch = this.setLocalSearch.bind(this);
    this.setSelectedBrand = this.setSelectedBrand.bind(this);
  }

  setAlert(message, color = "info") {
    this.setState({ alert: { message, color } });
  }

  clearAlert() {
    this.setState({ alert: null });
  }

  //
  /**
   * Update the search state to a new spec and/or value (or alternatively restore the default one)
   *
   * @param tab
   * @param searchSpec
   * @param searchValue
   */
  setSearchState(tab, { searchSpec, searchValue }) {
    this.setState(prevState => {
      // If a new spec was supplied, set the new spec/val and preserve the old one if it was the default
      if (searchSpec) {
        return {
          [tab]: {
            ...prevState[tab],
            prevSearch: prevState[tab].searchSpec.default ? {
              searchSpec: prevState[tab].searchSpec,
              searchValue: prevState[tab].searchValue,
            } : prevState[tab].prevSearch,
            searchSpec,
            searchValue,
          }
        }
      }
      // If only a value was supplied then just update the value of the current spec
      if (searchValue !== undefined) {
        return {
          [tab]: {
            ...prevState[tab],
            searchValue,
          }
        }
      }
      // If they supplied neither spec nor value then restore the default spec/value if it was preserved
      if (prevState[tab].prevSearch) {
        return {
          [tab]: {
            ...prevState[tab],
            ...prevState[tab].prevSearch,
          }
        }
      }
      return null;
    })
  }

  setLocalSearch(tab, searchText) {
    this.setState(prevState => ({
      [tab]: {
        ...prevState[tab],
        searchText
      }
    }));
  }

  setSelectedBrand(id) {
    let { history, location: { pathname}, match: { params: { brandId }}} = this.props;

    if (id === brandId) {
      return;
    }
    const brandEntry = BRAND_DATA[id];
    if (brandEntry) {
      const newPath = pathname.replace(brandId, id);
      history.replace(newPath);
      this.setState({selectedBrand: {id, ...brandEntry}});
    }
  }

  componentDidUpdate() {
    console.log(this.state);
  }

  render() {
    const { alert, selectedBrand } = this.state;

    const alertContext = {
      alert,
      setAlert: this.setAlert,
      clearAlert: this.clearAlert
    };

    const searchContext = {
      tabUsers: {
        ...this.state.tabUsers,
        setSearchState: params => this.setSearchState("tabUsers", params),
        setLocalSearch: params => this.setLocalSearch("tabUsers", params)
      },
      tabScans: {
        ...this.state.tabScans,
        setSearchState: params => this.setSearchState("tabScans", params),
        setLocalSearch: params => this.setLocalSearch("tabScans", params)
      },
      tabOrders: {
        ...this.state.tabOrders,
        setSearchState: params => this.setSearchState("tabOrders", params),
        setLocalSearch: params => this.setLocalSearch("tabOrders", params)
      }
    };

    const brandContext = {
      brands: BRAND_DATA,
      selectedBrand,
      setSelectedBrand: id => this.setSelectedBrand(id)
    };

    return (
      <BrandContext.Provider value={brandContext}>
        <ApolloProvider client={getEnv(selectedBrand.id).appSyncClient}>
          <Rehydrated>
            <AlertContext.Provider value={alertContext}>
              <SearchContext.Provider value={searchContext}>
                <Switch>
                  <Route exact path="/login" name="Login Page" component={Login} />
                  <Route exact path="/404" name="Page 404" component={Page404} />
                  <Route exact path="/500" name="Page 500" component={Page500} />
                  <PrivateRoute path={`/${selectedBrand.id}`} name="Home" component={DefaultLayout} />
                </Switch>
              </SearchContext.Provider>
            </AlertContext.Provider>
          </Rehydrated>
        </ApolloProvider>
      </BrandContext.Provider>
    );
  }
}

export default App;

import React from "react";

export const AlertContext = React.createContext();
export const SearchContext = React.createContext();
export const BrandContext = React.createContext();

export const withAlertContext = Component => props => (
  <AlertContext.Consumer>
    {({ alert, setAlert, clearAlert }) => (
      <Component
        alert={alert}
        setAlert={setAlert}
        clearAlert={clearAlert}
        {...props}
      />
    )}
  </AlertContext.Consumer>
);

export const withSearchContext = Component => props => (
  <SearchContext.Consumer>
    {context => <Component {...props} {...context} />}
  </SearchContext.Consumer>
);

export const withBrandContext = Component => props => (
  <BrandContext.Consumer>
    {context => <Component {...props} {...context} />}
  </BrandContext.Consumer>
);

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { Switch, Route, Redirect, HashRouter} from 'react-router-dom';
import App from "./App";
import * as serviceWorker from "./serviceWorker";

const root = document.getElementById("root");
ReactDOM.render((
  <HashRouter>
    <Switch>
      <Route path="/:brandId" component={App} />
      <Redirect from="/" to="/bare" />
    </Switch>
  </HashRouter>
), root);

serviceWorker.unregister();

import { MAX_LISTING_ENTRIES } from "./constants";
import { utc as moment } from "moment";

/*
 * Date manipulation function
 */

const DATE_FORMAT = "YYYY-MM-DD";
const DEFAULT_DATETIME_FORMAT = "MMM DD, YYYY hh:mm A z";

export function today() {
  return new Date().toISOString().substr(0, 10);
}
export function dateStrAdd(dateStr, value, unit) {
  return moment(dateStr, DATE_FORMAT)
    .add(value, unit)
    .format(DATE_FORMAT);
}

export function dateStrToDate(dateStr) {
  const [y, m, d] = dateStr.split("-");
  return new Date(parseInt(y), parseInt(m) - 1, parseInt(d));
}

export function dateToDateStr(date) {
  const y = date.getFullYear().toString();
  const m = (date.getMonth() + 1).toString();
  const d = date.getDate().toString();
  const mm = m < 10 ? "0" + m : m;
  const dd = d < 10 ? "0" + d : d;
  return `${y}-${mm}-${dd}`;
}

export function formatDateTime(value, fmtString = DEFAULT_DATETIME_FORMAT) {
  return moment(value).format(fmtString);
}

/*
 * Data Format Functions
 */

const currencyFormat = new Intl.NumberFormat("en-xa", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2
});

export function formatCurrency(value, nanValue = "N/A") {
  const fValue = parseFloat(value);
  return isNaN(fValue) ? nanValue : currencyFormat.format(value);
}

export function generateTrackingNo() {
  let trackingNo = "";
  for (let i = 0; i < 20; i++) {
    trackingNo += Math.floor(Math.random() * 10);
  }
  return trackingNo;
}

/*
 * Data Conversion Function
 */

export function numberToHex(number) {
  const hexString = number.toString(16);
  return hexString.length % 2 ? "0" + hexString : hexString;
}

export function colorInfoToHexRGB(color) {
  const { luminance, greenRed, blueYellow } = color;

  const { red, green, blue } =
    luminance != null ? lab2rgb([luminance, greenRed, blueYellow]) : color;

  const rr = numberToHex(Math.round(red * 255));
  const gg = numberToHex(Math.round(green * 255));
  const bb = numberToHex(Math.round(blue * 255));

  return `#${rr}${gg}${bb}`;
}

export function lab2rgb(lab) {
  let y = (lab[0] + 16) / 116;
  let x = lab[1] / 500 + y;
  let z = y - lab[2] / 200;
  let r, g, b;

  x = 0.95047 * (x * x * x > 0.008856 ? x * x * x : (x - 16 / 116) / 7.787);
  y = y * y * y > 0.008856 ? y * y * y : (y - 16 / 116) / 7.787;
  z = 1.08883 * (z * z * z > 0.008856 ? z * z * z : (z - 16 / 116) / 7.787);

  r = x * 3.2406 + y * -1.5372 + z * -0.4986;
  g = x * -0.9689 + y * 1.8758 + z * 0.0415;
  b = x * 0.0557 + y * -0.204 + z * 1.057;

  r = r > 0.0031308 ? 1.055 * Math.pow(r, 1 / 2.4) - 0.055 : 12.92 * r;
  g = g > 0.0031308 ? 1.055 * Math.pow(g, 1 / 2.4) - 0.055 : 12.92 * g;
  b = b > 0.0031308 ? 1.055 * Math.pow(b, 1 / 2.4) - 0.055 : 12.92 * b;

  return {
    red: Math.max(0, Math.min(1, r)),
    green: Math.max(0, Math.min(1, g)),
    blue: Math.max(0, Math.min(1, b))
  };
}

export function dispenseNote(dispense, fixed = 3) {
  const { base, yellow, red, black } = dispense;
  const baseAge = (base / (base + yellow + red + black)) * 100;
  const yellowAge = (yellow / (base + yellow + red + black)) * 100;
  const redAge = (red / (base + yellow + red + black)) * 100;
  const blackAge = (black / (base + yellow + red + black)) * 100;

  return `Dispense: {base: ${base.toFixed(fixed)}(g) ${baseAge.toFixed(
    fixed
  )}(%), yellow: ${yellow.toFixed(fixed)}(g) ${yellowAge.toFixed(
    fixed
  )}(%), red: ${red.toFixed(fixed)}(g) ${redAge.toFixed(
    fixed
  )}(%), black: ${black.toFixed(fixed)}(g) ${blackAge.toFixed(fixed)}(%)}`;
}

/*
 * Input Validator
 */

export function checkRequiredString(value) {
  return value != null && value.toString().trim().length > 0;
}

/*
 * Data sanitization
 */

export function sanitizeAddress(address) {
  // Remove empty Middle Name
  if (checkRequiredString(address.MiddleName) === false) {
    address.MiddleName = null;
  }
  // Remove empty address line 2
  if (checkRequiredString(address.AddressLine2) === false) {
    address.AddressLine2 = null;
  }

  delete address.__typename;
  return address;
}

export function sanitizeTypename(value) {
  if (value === null || value === undefined) {
    return value;
  } else if (Array.isArray(value)) {
    return value.map(v => sanitizeTypename(v));
  } else if (typeof value === "object") {
    const newObj = {};
    Object.entries(value).forEach(([key, v]) => {
      if (key !== "__typename") {
        newObj[key] = sanitizeTypename(v);
      }
    });
    return newObj;
  }
  return value;
}

export function createChangeNote(oldValue, newValue) {
  return Object.keys(oldValue)
    .filter(k => newValue[k] == null)
    .concat(Object.keys(newValue))
    .filter(k => k !== "__typename" && oldValue[k] !== newValue[k])
    .map(k => `${k}: ${oldValue[k]} --> ${newValue[k]}`)
    .join("; ");
}

/*
 * Data table filter/sorting
 */

function itemValue(item, key, extraData) {
  const value = item[key];
  const extra = extraData[key];

  return extra
    ? typeof extra === "function"
      ? extra(value)
      : extra[value]
    : value;
}

function filterData(data, state, extraData = {}) {
  const { filter } = state;
  return filter == null
    ? data
    : data.filter(item =>
        Object.entries(filter).every(([key, value]) => {
          const itemVal = itemValue(item, key, extraData);
          return (
            value === "*" ||
            itemVal == null ||
            (Array.isArray(itemVal)
              ? itemVal.includes(value)
              : itemVal === value)
          );
        })
      );
}

function searchData(data, state, extraData = {}, searchFields = null) {
  const { searchText } = state;
  const searchTextTrim = searchText && searchText.trim().toLowerCase();

  return searchTextTrim == null || searchTextTrim.length === 0
    ? data
    : data.filter(item =>
        Object.keys(item)
          .filter(key => !searchFields || searchFields.includes(key) === true)
          .map(key => itemValue(item, key, extraData))
          .some(
            value =>
              value != null &&
              Array.isArray(value) ?
                value.sort()
                  .join(',')
                  .toLowerCase()
                  .includes(searchTextTrim)
                :
                value
                  .toString()
                  .toLowerCase()
                  .includes(searchTextTrim)
          )
      );
}

export function sortData(data, state, extraData = {}) {
  const { sortedBy, sortedAsc } = state;
  const sortedDir = sortedAsc === true ? 1 : -1;

  function cmp(item1, item2) {
    const a = itemValue(item1, sortedBy, extraData);
    const b = itemValue(item2, sortedBy, extraData);
    if (a == null && b == null) return 0;
    if (a == null) return -sortedDir;
    if (b == null) return sortedDir;
    const aStr = a.toString();
    const bStr = b.toString();
    return aStr > bStr ? sortedDir : aStr < bStr ? -sortedDir : 0;
  }

  return data.sort(cmp);
}

// Combination of filter data and sort data
export function tabledata({ data, state, extraData = {}, options = {} }) {
  const defaultOptions = {
    filter: true,
    search: true,
    sort: true,
    maxListingEntries: MAX_LISTING_ENTRIES,
    searchFields: null
  };
  options = {
    ...defaultOptions,
    ...options
  };

  const pass1 =
    options.filter === false ? data : filterData(data, state, extraData);
  const pass2 =
    options.search === false
      ? pass1
      : searchData(pass1, state, extraData, options.searchFields);
  const pass3 =
    options.sort === false ? pass2 : sortData(pass2, state, extraData);

  return {
    filtered: pass1 !== data,
    searched: pass2 !== pass1,
    sorted: pass3 !== pass2,
    data: MAX_LISTING_ENTRIES > 0 ? pass3.slice(0, MAX_LISTING_ENTRIES) : pass3
  };
}

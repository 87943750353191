export const VERSION = "11152019";
export const NUMBER_ENTRIES_PER_PAGE = 50;

export const PENDING = "pending";
export const PROCESSED = "processed";
export const SHIPPED = "shipped";
export const PRINTED = "printed";
export const PRINTED_plus = "printed+";

export const ORDER_STATUSES = [
  PENDING,
  "queued",
  PRINTED,
  PRINTED_plus,
  PROCESSED,
  "packaged",
  SHIPPED,
  "delivered",
  "-",
  "hold"
];

export const MFG_LOCATIONS = ["Santa Monica", "SAI"];

export const STD_DELIVERY = "STD_DELIVERY";
export const SHIPPING_METHODS = [STD_DELIVERY, "standard", "premium", "pickup"];

export const NOTE_CATEGORY = {
  order: "Order updates",
  scan: "Scan updates",
  user: "User updates"
};

export const DEFAULT_LIMIT_PER_SCAN = 1000;

export const MAX_LISTING_ENTRIES = 0;

export const DOWNLOAD_CSV_OPTS = {
  fileName: "scans.csv",
  index: "gsi-UserScans-All",
  keyName: "userID",
  fields: {
    "User Id": "user.id",
    L: "skintone.color.l",
    A: "skintone.color.a",
    B: "skintone.color.b",
    Platform: "platform",
    "App Version": "appVersion",
    "OS Version": "osVersion",
    "Output Version": "outputVersion",
    "Scan Time Stamp": "createdAt"
  }
};

export const SEARCH_BY_DATE_INDEX = {
  type: "index",
  index: "createdByDate",
  field: "createdByDate"
};

export const SEARCH_BY_STATUS_FILTER = {
  type: "list",
  filter: {
    and: [
      { OrderStatus: { ne: "shipped" } },
      { OrderStatus: { ne: "canceled" } },
      { OrderStatus: { ne: "delivered" } }
    ]
  }
};

// The list of brand identifiers
export const BRAND_IDS = {
  BARE_MINERALS: "bare",
  LAURA_MERCIER: "lm",
  NARS: "nars"
};

// Later maybe this is retrieved in localStorage or some such
export const DEFAULT_BRAND = BRAND_IDS.BARE_MINERALS;

// Visual styles for the admin tool for each brand with name, font family and color
export const BRAND_DATA = {
  [BRAND_IDS.BARE_MINERALS]: {
    name: "bareMinerals",
    font: "Rockwell-Regular, serif",
    color: "#0e306b",
    products: {
      prefix: "US",
      ids: ["94403", "94404"],
      map: {
        "94403": "MADE-2-FIT BAREPRO Liquid Foundation SPF 15",
        "94404": "MADE-2-FIT BAREPRO Liquid Foundation"
      },
      batchIds: {
        US94404: "M2F",
        US94403: "PRO"
      },
      formulaIds: ["Made2Fit", "BarePro"]
    }
  },
  [BRAND_IDS.LAURA_MERCIER]: {
    name: "LAURA MERCIER",
    font: "Gotham, sans-serif",
    color: "#db895e",
    products: {
      prefix: "",
      ids: ["12710782U", "12710781U", "12711093U"],
      map: {
        "12710782U": "Flawless Fusion Ultra-Longwear Foundation",
        "12710781U":
          "Flawless Fusion Ultra-Longwear Foundation (with hydrating primer sample)",
        "12711093U":
          "Flawless Fusion Ultra-Longwear Foundation (with TLSP sample)"
      },
      batchIds: {
        "12710782U": "LM-FlawlessFusionFoundation",
        "12711093U": "LM-FlawlessFusionWithSamples",
        "12710781U": "LM-FlawlessFusionFoundation"
      },
      formulaIds: ["LMFFF"],
      includeSkinTone: true,
      useBottleName: true
    }
  },
  [BRAND_IDS.NARS]: {
    name: "NARS",
    font: "HelveticaNeueLTStd-UltLt, sans-serif",
    color: "#000000",
    disabled: true
  }
};

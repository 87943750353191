import React, { Component } from "react";
import { withRouter, Route, Redirect } from "react-router-dom";

import { Auth } from "aws-amplify";

const LOGIN_PATH = "/login";

class PrivateRoute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      isAuthenticated: false
    };
  }

  componentDidMount() {
    this.authenticate();
    this.unlisten = this.props.history.listen(() => {
      Auth.currentAuthenticatedUser().catch(
        () =>
          this.state.isAuthenticated &&
          this.setState({ isAuthenticated: false })
      );
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  authenticate() {
    Auth.currentAuthenticatedUser()
      .then(() =>
        this.setState({
          loaded: true,
          isAuthenticated: true
        })
      )
      .catch(() => this.props.history.push(LOGIN_PATH));
  }

  render() {
    const { component: Component, ...rest } = this.props;
    const { loaded, isAuthenticated } = this.state;
    if (!loaded) return null;
    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated ? (
            <Component {...props} />
          ) : (
            <Redirect to={LOGIN_PATH} />
          )
        }
      />
    );
  }
}

export default withRouter(PrivateRoute);
